import React, { forwardRef } from 'react';
import ListingImage from '../EditListingPhotosPanel/ListingImage';
import { isArrayLength } from '../../../../util/genericHelpers';
import { Icons } from '../../../../components';

import css from './ImagesRedorderWrapper.module.css';

const ReorderItem = forwardRef(
  ({ id, withOpacity, renderImages, onRemoveImage, images, isDragging, style, ...props }, ref) => {
    const currentImage = isArrayLength(images) && images.find(i => i?.id === id?.id);

    const inlineStyles = {
      opacity: withOpacity ? '0.5' : '1',
      transformOrigin: '50% 50%',
      cursor: isDragging ? 'grabbing' : 'grab',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      ...style,
    };

    return (
      <div key={id} className={css.reorderWrapperItem}>
        <div ref={ref} className={css.reorderImages} style={inlineStyles} {...props}>
          {currentImage ? (
            <ListingImage
              image={currentImage}
              key={id?.id?.uuid || id?.id}
              className={css.thumbnail}
              savedImageAltText="Tutulist"
              hideRemoveImage
              onRemoveImage={() => onRemoveImage(id?.id)}
            />
          ) : null}
        </div>
        <div className={css.removeImage} onClick={() => onRemoveImage(id?.id)}>
          <Icons name="removeIcon" />
        </div>
      </div>
    );
  }
);

export default ReorderItem;
