import { DETAILS, JOB } from '../containers/EditListingPage/EditListingWizard/EditListingWizardTab';
import { ListingType } from './enums';
import { isFieldForCategory, isFieldForListingType, pickCategoryFields } from './fieldHelpers';
import { EXTENDED_DATA_SCHEMA_TYPES, SCHEMA_TYPE_ENUM } from './types';
import { types as sdkTypes } from '../util/sdkLoader';
import { createSlug } from './urlHelpers';
import { createResourceLocatorString } from './routes';
import { isArrayLength } from './genericHelpers';
const { Money } = sdkTypes;

/**
 * Pick extended data fields from given extended data of the listing entity.
 * Picking is based on extended data configuration for the listing and target scope and listing type.
 *
 * This returns namespaced (e.g. 'pub_') initial values for the form.
 *
 * @param {Object} data extended data values to look through against listingConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetListingType Check that the extended data is relevant for this listing type.
 * @param {Object} listingFieldConfigs an extended data configurtions for listing fields.
 * @returns Array of picked extended data fields
 */
const initialValuesForListingFields = (
  data,
  targetScope,
  targetListingType,
  targetCategories,
  listingFieldConfigs
) => {
  const targetCategoryIds = Object.values(targetCategories);

  return listingFieldConfigs.reduce((fields, fieldConfig) => {
    const { key, scope = 'public', schemaType, enumOptions } = fieldConfig || {};
    const namespacePrefix = scope === 'public' ? `pub_` : `priv_`;
    const namespacedKey = `${namespacePrefix}${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isEnumSchemaType = schemaType === SCHEMA_TYPE_ENUM;
    const shouldHaveValidEnumOptions =
      !isEnumSchemaType ||
      (isEnumSchemaType && !!enumOptions?.find(conf => conf.option === data?.[key]));
    const isTargetScope = scope === targetScope;
    const isTargetListingType = isFieldForListingType(targetListingType, fieldConfig);
    const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

    if (
      isKnownSchemaType &&
      isTargetScope &&
      isTargetListingType &&
      isTargetCategory &&
      shouldHaveValidEnumOptions
    ) {
      const fieldValue = data?.[key] || null;
      return { ...fields, [namespacedKey]: fieldValue };
    }
    return fields;
  }, {});
};

/**
 * Get listing configuration. For existing listings, it is stored to publicData.
 * For new listings, the data needs to be figured out from listingTypes configuration.
 *
 * In the latter case, we select first type in the array. However, EditListingDetailsForm component
 * gets 'selectableListingTypes' prop, which it uses to provide a way to make selection,
 * if multiple listing types are available.
 *
 * @param {Array} listingTypes
 * @param {Object} existingListingTypeInfo
 * @returns an object containing mainly information that can be stored to publicData.
 */
export const getTransactionInfo = (
  listingTypes,
  existingListingTypeInfo = {},
  inlcudeLabel = false
) => {
  const { listingType, transactionProcessAlias, unitType } = existingListingTypeInfo;

  if (listingType && transactionProcessAlias && unitType) {
    return { listingType, transactionProcessAlias, unitType };
  } else if (listingTypes.length === 1) {
    const { listingType: type, label, transactionType } = listingTypes[0];
    const { alias, unitType: configUnitType } = transactionType;
    const labelMaybe = inlcudeLabel ? { label: label || type } : {};
    return {
      listingType: type,
      transactionProcessAlias: alias,
      unitType: configUnitType,
      ...labelMaybe,
    };
  }
  return {};
};

/**
 * Get initialValues for the form. This function includes
 * title, description, listingType, transactionProcessAlias, unitType,
 * and those publicData & privateData fields that are configured through
 * config.listing.listingFields.
 *
 * @param {object} props
 * @param {object} existingListingTypeInfo info saved to listing's publicData
 * @param {object} listingTypes app's configured types (presets for listings)
 * @param {object} listingFields those extended data fields that are part of configurations
 * @returns initialValues object for the form
 */
export const getInitialValues = (
  props,
  existingListingTypeInfo,
  listingTypes,
  listingFields,
  listingCategories,
  categoryKey,
  currency
) => {
  const { description, title, publicData, privateData, geolocation } =
    props?.listing?.attributes || {};

  const {
    listingType,
    skills,
    skillLevel,
    experienceLevel,
    marketType,
    budget,
    priceFrom = 0,
    priceTo = 0,
    fixedPrice = 0,
    designerLevel,
    keyResponsibilities,
    workExperience,
    hiringLocation,
    category,
  } = publicData || {};

  const nestedCategories = pickCategoryFields(publicData, categoryKey, 1, listingCategories);

  // Helper function to create Money object
  const createMoney = (amount, currency) => new Money(amount || 0, currency);
  const locationFieldsPresent = hiringLocation?.address && geolocation;
  const { address } = hiringLocation || {};

  // Initial values for the form
  return {
    title,
    description,
    skills,
    skillLevel,
    experienceLevel,
    marketType,
    budget : 'fixed',
    designerLevel,
    category,
    keyResponsibilities: keyResponsibilities || [''],
    hiringLocation: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin: geolocation },
        }
      : null,
    workExperience: workExperience || [''],
    priceFrom: createMoney(priceFrom, currency),
    priceTo: createMoney(priceTo, currency),
    fixedPrice: createMoney(fixedPrice, currency),
    ...nestedCategories,
    ...getTransactionInfo(listingTypes, existingListingTypeInfo),
    ...initialValuesForListingFields(
      publicData,
      'public',
      listingType,
      nestedCategories,
      listingFields
    ),
    ...initialValuesForListingFields(
      privateData,
      'private',
      listingType,
      nestedCategories,
      listingFields
    ),
  };
};
/**
 * Check if listingType has already been set.
 *
 * If listing type (incl. process & unitType) has been set, we won't allow change to it.
 * It's possible to make it editable, but it becomes somewhat complex to modify following panels,
 * for the different process. (E.g. adjusting stock vs booking availability settings,
 * if process has been changed for existing listing.)
 *
 * @param {Object} publicData JSON-like data stored to listing entity.
 * @returns object literal with to keys: { hasExistingListingType, existingListingTypeInfo }
 */
export const hasSetListingType = publicData => {
  const { listingType, transactionProcessAlias, unitType } = publicData;
  const existingListingTypeInfo = { listingType, transactionProcessAlias, unitType };

  return {
    hasExistingListingType: !!listingType && !!transactionProcessAlias && !!unitType,
    existingListingTypeInfo,
  };
};

export const getListingType = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.type;
};

export const identity = v => v;

export const getListingUnitType = listing => {
  if (listing?.id) return null;
  return listing?.attributes?.publicData?.unitType;
};

// Helper function to navigate to listing page
export const navigateToListingPage = ({ listingId, listingTitle, history, routeConfiguration }) => {
  history.push(
    createResourceLocatorString('ListingPage', routeConfiguration, {
      id: listingId,
      slug: createSlug(listingTitle),
    })
  );
};

export const navigateTo = async ({ listingId, listingTitle, routeConfiguration, history }) => {
  navigateToListingPage({ listingId, listingTitle, history, routeConfiguration });
};

export const determineListingTab = listing => {
  // If the listing ID is not available, return null
  if (!listing?.id) return null;

  // Extract listingType from publicData attributes
  const listingType = getListingType(listing);

  // Get the parent listing ID

  // Determine the tab based on listingType and parentListingId
  let listingTab;
  if (listingType === ListingType.COMPANY) {
    listingTab = JOB;
  } else {
    listingTab = DETAILS;
  }

  return listingTab;
};

export const getAuthorName = listing => {
  return listing?.author?.attributes?.profile?.displayName;
};

// Helper function to upload image
export const handleImageUpload = async (sdk, imageFile) => {
  const response = await sdk.images.upload({ image: imageFile });
  return response.data?.data || {};
};

// Helper function to update listing
export const updateListing = async (sdk, params) => {
  try {
    return sdk.ownListings.update(params);
  } catch (error) {}
};
