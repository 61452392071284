import { Budget, DEFAULT_HOURLY } from './enums';

export const getBudgetType = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.budget;
};

export const getTxType = tx => {
  return tx?.attributes?.processName === DEFAULT_HOURLY ? Budget.HOURLY : Budget.FIXED;
};

export const getJobCategory = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.marketType;
};
export const getDesignerLevel = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.designerLevel;
};

export const getListingPrice = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.price;
};
export const getListingContractDetails = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.contractDetails || {};
};

export const getListingDetails = listing => {
  if (!listing?.id) return null;
  const { publicData = {}, title, description, createdAt } = listing?.attributes || {};

  const { designerLevel, marketType, skillLevel, keyResponsibilities, workExperience } =
    publicData || {};

  return {
    listingTitle: title,
    description,
    designerLevel,
    marketType,
    skillLevel,
    createdAt,
    keyResponsibilities,
    workExperience,
  };
};

export const getKeyResponsibilities = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.keyResponsibilities || [];
};

export const getWorkExperience = listing => {
  if (!listing?.id) return null;
  return listing?.attributes?.publicData?.workExperience || [];
};
export const getListingTitleAndDescription = listing => {
  if (!listing?.id) return null;
  const { title, description } = listing?.attributes || {};
  return {
    title,
    description,
  };
};
