import React from 'react';
import css from './EditListingJobForm.module.css';
import { FieldArray } from 'react-final-form-arrays';
import { FieldTextInput, Icons } from '../../../../components';
import { required } from '../../../../util/validators';

const WorkExperienceSection = ({ intl, formId, push }) => {
  return (
    <div className={css.keyResponsibilitiesSection}>
      <FieldArray name="workExperience">
        {({ fields }) => {
          return fields.map((name, index) => (
            <div key={name} className={css.responsibilityField}>
              <FieldTextInput
                type="text"
                id={`workExperience.${index}`}
                name={name}
                label={intl.formatMessage({ id: 'WorkExperienceSection.title' })}
                placeholder={intl.formatMessage({ id: 'WorkExperienceSection.placeholder' })}
                className={css.textField}
                validate={required(intl.formatMessage({ id: 'WorkExperienceSection.required' }))}
              />
              {index > 0 && (
                <button
                  type="button"
                  className={css.removeButton}
                  onClick={() => fields.remove(index)}
                  disabled={fields.length === 1}
                >
                  {intl.formatMessage({ id: 'WorkExperienceSection.removeButton' })}
                </button>
              )}
            </div>
          ));
        }}
      </FieldArray>
      <button type="button" className={css.addButton} onClick={() => push('workExperience', '')}>
        <Icons name="plus" /> {intl.formatMessage({ id: 'WorkExperienceSection.addButton' })}
      </button>
    </div>
  );
};

export default WorkExperienceSection;
