import React from 'react';
import css from './EditListingJobForm.module.css';
import { FieldArray } from 'react-final-form-arrays';
import { FieldTextInput, Icons } from '../../../../components';
import { required } from '../../../../util/validators';

const KeyResponsibilitiesSection = ({ intl, formId, push }) => {
  return (
    <div className={css.keyResponsibilitiesSection}>
      <FieldArray name="keyResponsibilities">
        {({ fields }) => {
          return fields.map((name, index) => (
            <div key={name} className={css.responsibilityField}>
              <FieldTextInput
                type="text"
                id={`keyResponsibilities.${index}`}
                name={name}
                label={intl.formatMessage({ id: 'KeyResponsibilitiesSection.title' })}
                placeholder={intl.formatMessage({ id: 'KeyResponsibilitiesSection.placeholder' })}
                className={css.textField}
                validate={required(
                  intl.formatMessage({ id: 'KeyResponsibilitiesSection.required' })
                )}
              />
              {index > 0 && (
                <button
                  type="button"
                  className={css.removeButton}
                  onClick={() => fields.remove(index)}
                  disabled={fields.length === 1}
                >
                  {intl.formatMessage({ id: 'KeyResponsibilitiesSection.removeButton' })}
                </button>
              )}
            </div>
          ));
        }}
      </FieldArray>
      <button
        type="button"
        className={css.addButton}
        onClick={() => push('keyResponsibilities', '')}
      >
        <Icons name="plus" /> {intl.formatMessage({ id: 'KeyResponsibilitiesSection.addButton' })}
      </button>
    </div>
  );
};

export default KeyResponsibilitiesSection;
