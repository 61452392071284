import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import {
  DEFAULT_HOURLY_PROCESS,
  DEFAULT_INQUIRY_PROCESS,
  DEFAULT_INQUIRY_UNIT_TYPE,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../../../util/types';
import css from './EditListingJobPanel.module.css';
import { useSelector } from 'react-redux';
import { Budget, LISTING_CONFIG_KEYS, ListingType, UserType } from '../../../../util/enums';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { showErrorToast } from '../../../../util/toast';
import {
  getInitialValues,
  getListingUnitType,
  getTransactionInfo,
  hasSetListingType,
} from '../../../../util/editListingHelpers';
import EditListingJobForm from './EditListingJobForm';
import { currentUserSelector } from '../../../../ducks/user.duck';
import { getUserType } from '../../../../util/userDataExtraction';
import { JOB } from '../EditListingWizardTab';
import {
  createDraftListingSelector,
  createPublishedListingSelector,
  imageIds,
} from '../../EditListingPage.duck';
import { isArrayLength } from '../../../../util/genericHelpers';
import { types as sdkTypes } from '../../../../util/sdkLoader';

const getEnumOptions = (config, key) => config.find(item => item?.key === key)?.enumOptions || [];

const { UUID } = sdkTypes;

const EditListingJobPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onListingTypeChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    config,
    history,
    params,
    onUpdateListing,
    tab,
    isNewURI,
    onPublishListingDraft,
    onCreateListingDraft,
    onCreatePublishListing,
    images,
    onImageUpload,
    onRemoveImage,
    listingImageConfig,
  } = props;

  const { publishListingError, publishListingInProgress } = useSelector(
    createPublishedListingSelector
  );
  const { createListingDraftInProgress } = useSelector(createDraftListingSelector);
  const unitType = getListingUnitType(listing);
  const isPublished = listing?.id && listing?.attributes?.state === LISTING_STATE_PUBLISHED;
  const isPendingApproval =
    listing?.id && listing?.attributes?.state === LISTING_STATE_PENDING_APPROVAL;
  const routeConfiguration = useRouteConfiguration();
  const currentUser = useSelector(currentUserSelector);
  const userType = getUserType(currentUser);
  const classes = classNames(rootClassName || css.root, className);
  const { publicData, state, privateData } = listing?.attributes || {};
  const listingTypes = config.listing.listingTypes;
  const listingFieldsConfig = config.listing.listingFields;
  const { listingType } = publicData || {};
  const listingFields = config.listing.listingFields;
  const listingCategories = config.categoryConfiguration.categories;
  const categoryKey = config.categoryConfiguration.key;
  const { hasExistingListingType, existingListingTypeInfo } = hasSetListingType(publicData);

  const initialValues = getInitialValues(
    props,
    existingListingTypeInfo,
    listingTypes,
    listingFields,
    listingCategories,
    categoryKey,
    config.currency
  );

  const handleJobSumit = async values => {
    try {
      const {
        title,
        budget,
        description,
        priceFrom,
        priceTo,
        fixedPrice,
        isDraft,
        images: imagesArray,
        transactionProcessAlias,
        hiringLocation,
        ...rest
      } = values;

      const {
        selectedPlace: { address, origin },
      } = hiringLocation;

      const imagesData = isArrayLength(imagesArray) && imagesArray.map(i => new UUID(i));
      // New values for listing attributes
      const updatedValues = {
        title,
        description,
        geolocation: origin,
        price: budget === Budget.FIXED ? fixedPrice : priceFrom,
        publicData: {
          priceTo: budget === Budget.HOURLY ? priceTo?.amount : null,
          priceFrom: budget === Budget.HOURLY ? priceFrom?.amount : null,
          fixedPrice: fixedPrice?.amount || null,
          budget,
          transactionProcessAlias:
            budget === Budget.FIXED ? DEFAULT_INQUIRY_PROCESS : DEFAULT_HOURLY_PROCESS,
          unitType: DEFAULT_INQUIRY_UNIT_TYPE,
          type: ListingType.COMPANY,
          hiringLocation: { address },
          contractDetails: {
            providerId: currentUser?.id?.uuid,
            shouldShowOnSearchPage: true,
          },
          listingUnderContract: false,
          ...rest,
        },
      };
      if (isArrayLength(imagesData)) {
        updatedValues.images = imagesData;
      } else if (isArrayLength(imageIds(images))) {
        updatedValues.images = imageIds(images);
      }
      // Helper function to handle listing operations
      const handleListingOperations = async () => {
        const redirectToManageListings = () => {
          history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration, {}));
        };

        const setId = () => {
          updatedValues.id = listing?.id;
        };
        if (!isNewURI && !isDraft && !isPublished && !isPendingApproval) {
          setId();
          const response = await onPublishListingDraft(updatedValues);
          redirectToManageListings();
          return response;
        } else if ((isPublished && !isNewURI) || isPendingApproval) {
          setId();
          const response = await onUpdateListing(JOB, updatedValues, config);
          redirectToManageListings();
          return response;
        } else if (isDraft && isNewURI) {
          const response = await onCreateListingDraft(updatedValues, config);
          if (response.id?.uuid) {
            redirectToManageListings();
            return null;
          }
          redirectToManageListings();
          return response;
        } else {
          const response = await onCreatePublishListing(updatedValues, config);
          redirectToManageListings();
          return response;
        }
      };
      await handleListingOperations();
    } catch (error) {
      showErrorToast('error', 'Error while creating job listing. Please try again later.');
    }
  };

  const categoryOptions = getEnumOptions(listingFieldsConfig, LISTING_CONFIG_KEYS.CATEGORY);

  const experienceLevelOptions = getEnumOptions(
    listingFieldsConfig,
    LISTING_CONFIG_KEYS.EXPERIENCE_LEVEL
  );
  const marketTypeOptions = getEnumOptions(
    listingFieldsConfig,
    LISTING_CONFIG_KEYS.PROJECT_SIZE_LISTING
  );

  return (
    <div className={classes}>
      <EditListingJobForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={handleJobSumit}
        onListingTypeChange={onListingTypeChange}
        listingType={listingType}
        listingFieldsConfig={listingFieldsConfig}
        selectableListingTypes={listingTypes.map(conf => getTransactionInfo([conf], {}, true))}
        hasExistingListingType={hasExistingListingType}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        categoryOptions={categoryOptions}
        marketTypeOptions={marketTypeOptions}
        experienceLevelOptions={experienceLevelOptions}
        createListingDraftInProgress={createListingDraftInProgress}
        fetchErrors={errors}
        config={config}
        unitType={unitType}
        isPublishedOrPendingApproval={isPublished || isPendingApproval}
        isNewURI={isNewURI}
        updateInProgress={publishListingInProgress || updateInProgress}
        autoFocus
        images={images}
        currentListing={listing}
        onImageUpload={onImageUpload}
        onRemoveImage={onRemoveImage}
        listingImageConfig={listingImageConfig}
      />
    </div>
  );
};

EditListingJobPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingJobPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingJobPanel;
