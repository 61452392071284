import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import ReorderItem from './ReorderItem';

import { isArrayLength } from '../../../../util/genericHelpers';
import PhotosFormDropZone from './PhotosFormDropZone';

import css from './ImagesRedorderWrapper.module.css';

function ImagesRedorderWrapper(props) {
  const {
    photosFormDropZoneContainer,
    renderImages,
    onReorder,
    onRemoveImage,
    images,
    intl,
    onImageUploadHandler,
  } = props;

  const [items, setItems] = useState(images);

  useEffect(() => {
    if (images?.length !== items?.length) {
      setItems(images);
    }
  }, [images]);

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback(event => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(event => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active?.id);
        const newIndex = items.indexOf(over?.id);
        onReorder(arrayMove(items, oldIndex, newIndex), { oldIndex, newIndex });
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const remainingBoxes = isArrayLength(items) ? 10 - items?.length : null;
  const containerRef = useRef(null);
  const widthRef = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    // when the component gets mounted
    const calWidth = () => {
      if (containerRef?.current?.childNodes) {
        [...containerRef.current.childNodes].map(item => {
          if (item?.className?.toString().includes('reorderWrapperItem')) {
            widthRef.current = item.offsetWidth;
          }
        });
      }

      if (widthRef.current) {
        setWidth(widthRef.current);
      }
    };
    calWidth();

    window.addEventListener('resize', calWidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener('resize', calWidth);
  }, [items]);

  return (
    <div className={css.reorderWrapper} ref={containerRef}>
      {!isArrayLength(items) ? (
        photosFormDropZoneContainer
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={items} strategy={rectSortingStrategy}>
            {isArrayLength(items) &&
              items.map((id, i) => (
                <React.Fragment key={i}>
                  <SortableItem
                    key={id}
                    id={id}
                    images={images}
                    renderImages={renderImages}
                    onRemoveImage={onRemoveImage}
                  />
                </React.Fragment>
              ))}{' '}
          </SortableContext>
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? (
              <React.Fragment ref={containerRef}>
                <ReorderItem
                  id={activeId}
                  images={images}
                  renderImages={renderImages}
                  onRemoveImage={onRemoveImage}
                  isDragging
                />
              </React.Fragment>
            ) : null}
          </DragOverlay>
        </DndContext>
      )}
      {isArrayLength(items) ? (
        <div className={css.photosFormDropZoneContainer} style={{ height: width }}>
          <PhotosFormDropZone
            isSingleUpload
            images={items}
            onAttachmentUpload={onImageUploadHandler}
            label={intl.formatMessage({
              id: 'PhotosFormDropZone.chooseAnImageTitle',
            })}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ImagesRedorderWrapper;
