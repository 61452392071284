import axios from 'axios';
import moment from 'moment';
import { isArrayLength } from './genericHelpers';
import { apiBaseUrl, deleteFileAWS } from './api';
export async function uploadFiles({
  newFiles,
  form,
  fieldName,
  onUploadProgress,
  setIsFileUploading,
}) {
  const currentFiles = form.getState().values[fieldName] || [];

  setIsFileUploading(true);

  if (!isArrayLength(newFiles)) {
    setIsFileUploading(false);

    return;
  }

  const filteredFiles = currentFiles.filter(item => item?.fileLink) || [];
  const updatedFiles = [...filteredFiles];

  try {
    for (const file of newFiles) {
      const formData = new FormData();
      formData.append('file', file);

      const { data = {} } = await axios.post(`${apiBaseUrl()}/api/upload-file-aws`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent, file.name);
          }
        },
      });

      const { data: awsResponse = {} } = data;
      const { objectURL, key } = awsResponse;
      const currentDate = moment().format('MM-DD-YYYY hh:mm:ss');

      updatedFiles.push({
        fileLink: objectURL,
        date: currentDate,
        name: file.name,
        size: file.size,
        key,
      });
    }

    form.change(fieldName, updatedFiles);
  } catch (error) {
  } finally {
    setIsFileUploading(false);
  }
}
export async function handleDeleteFile(params) {
  const { file, form, fieldName } = params;
  if (!file) return null;
  const files = form.getState().values?.[fieldName]?.filter(item => item?.fileLink) || [];
  try {
    const filteredFiles = files?.filter(item => item?.key !== file?.key);
    const response = await deleteFileAWS({ key: file.key });
    if (response.statusCode === 200) {
      form.change(fieldName, filteredFiles);
    }
  } catch (error) {
    throw new Error('Error while deleting a file: ' + error.message);
  }
}
