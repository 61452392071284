export const getUserType = user => {
  if (!user?.id) return null;
  return user?.attributes?.profile?.publicData?.userType;
};

export const hasStripeConnection = user => {
  if (!user.id) return false;
  return user?.attributes?.stripeConnected;
};
export const hasDefaultPaymentMethod = user => {
  if (!user.id) return false;
  return !!user?.stripeCustomer?.defaultPaymentMethod?.id;
};
export const getStripePaymentMethodId = user => {
  if (!user?.id) return null;
  return user?.stripeCustomer?.defaultPaymentMethod?.attributes?.stripePaymentMethodId;
};
export const getStripeCustomerId = user => {
  if (!user?.id) return null;
  return user?.stripeCustomer?.attributes?.stripeCustomerId;
};
export const getUserDisplayName = user => {
  if (!user.id) return null;
  return user?.attributes?.profile?.displayName;
};
