import { sendEmail } from '../util/api';
import { storableError } from '../util/errors';
import { fetchCurrentUser } from './user.duck';

// ================ Action types ================ //

export const SEND_NOTIFICATION_REQUEST = 'app/sendgrid/SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESSS = 'app/sendgrid/SEND_NOTIFICATION_SUCCESSS';
export const SEND_NOTIFICATION_ERROR = 'app/sendgrid/SEND_NOTIFICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendgridResponse: null,

  // verification
  sendNotificationError: null,
  sendNotoficationInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        sendNotoficationInProgress: true,
        sendNotificationError: null,
      };
    case SEND_NOTIFICATION_SUCCESSS:
      return {
        ...state,
        sendNotoficationInProgress: false,
        sendNotificationError: null,
        sendgridResponse: payload,
      };
    case SEND_NOTIFICATION_ERROR:
      return { ...state, sendNotoficationInProgress: false, sendNotificationError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const sendNotoficationInProgress = state => {
  return state.sendgrid.sendNotoficationInProgress;
};
export const sendNotificationSelector = state => {
  const { sendNotoficationInProgress, sendNotificationError, sendgridResponse } =
    state.sendgrid || {};
  return {
    sendNotoficationInProgress,
    sendgridResponse,
    sendNotificationError,
  };
};

// ================ Action creators ================ //

export const sendNotificationRequest = () => ({ type: SEND_NOTIFICATION_REQUEST });
export const sendNotificationSuccesss = data => ({
  type: SEND_NOTIFICATION_SUCCESSS,
  payload: data,
});
export const sendNotificationError = error => ({
  type: SEND_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const sendNotification = params => async (dispatch, getState, sdk) => {
  if (sendNotoficationInProgress(getState())) {
    return Promise.reject(new Error('Send notification is already in progress'));
  }

  dispatch(sendNotificationRequest());
  try {
    const response = await sendEmail({ ...params });
    dispatch(sendNotificationSuccesss(response));
    return response;
  } catch (error) {
    dispatch(sendNotificationError(storableError(error)));
  }
};
