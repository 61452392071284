import React from 'react';
import { FieldCheckboxGroup } from '../../../../components';
import * as validators from '../../../../util/validators';

import css from './EditListingJobForm.module.css';

const ManufacturerSection = ({ intl, formId, categoryOptions }) => {
  return (
    <div className={css.skillSection}>
      <div className={css.skillLevelSection}>
        <FieldCheckboxGroup
          name="category"
          id={`${formId}.category`}
          label={intl.formatMessage({ id: 'EditListingJobForm.skillLevelLabel' })}
          options={categoryOptions.map((option) => ({
            key: option.option,
            label: option.label,
          }))}
          validate={validators.required(
            intl.formatMessage({ id: 'EditListingJobForm.skillLevelRequired' })
          )}
          className={css.formFld}
          twoColumns
        />
      </div>
    </div>
  );
};

export default ManufacturerSection;
