import React from 'react';
import { FieldRadioButton } from '../../../../components';
import css from './EditListingJobForm.module.css';

export const designerLevels = [
  {
    label: 'Rising Star Designer',
    option: 'rising-star-designer',
    description: '1 - 2.5 years experience',
  },
  {
    label: 'Star Designer',
    option: 'star-designer',
    description: '3 - 5 years experience',
  },
  {
    label: 'Seasoned Veteran Designer',
    option: 'seasoned-veteran-designer',
    description: '6+ years experience',
  },
];

const ExperienceSection = ({ intl, showAsRequired, experienceLevelOptions }) => {
  const mergedOptions = experienceLevelOptions.map((option, index) => ({
    ...option,
    description: designerLevels[index].description,
  }));

  return (
    <div className={css.experienceSection}>
      <label className={css.skillLabel}>
        {intl.formatMessage({
          id: 'EditListingJobForm.experienceLevelLabel',
        })}
      </label>
      {mergedOptions.map(({ option, label, description }) => (
        <div key={option} className={css.experienceLevel}>
          <FieldRadioButton
            id={`designerLevel.${option}`}
            name="designerLevel"
            label={
              <div className={css.expLabelText}>
                <strong>{label}</strong>
                <p className={css.description}>{description}</p>
              </div>
            }
            value={option}
            showAsRequired={showAsRequired}
          />
        </div>
      ))}
    </div>
  );
};

export default ExperienceSection;
