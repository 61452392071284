import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from '../../../../util/reactIntl';

import css from './ImagesRedorderWrapper.module.css';
import { Icons } from '../../../../components';

function PhotosFormDropZone(props) {
  const intl = useIntl();

  const { onAttachmentUpload, isSingleUpload = false, images, acceptedTypes } = props;
  const [numFilesUploaded, setNumFilesUploaded] = useState(0);
  const maxUploads = 10; // Maximum number of uploads allowed

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    onDrop: acceptedFiles => {
      const remainingUploads = maxUploads - images?.length;
      const filesToAdd = acceptedFiles.slice(0, remainingUploads);
      filesToAdd.forEach(file => {
        onAttachmentUpload(file);
        setNumFilesUploaded(prevNum => prevNum + 1);
      });
    },
    disabled: numFilesUploaded >= maxUploads,
    maxFiles: 10,
  });

  const singleUploadLabel = intl.formatMessage({
    id: 'PhotosFormDropZone.singleUploadLabel',
  });

  return (
    <div className={css.addImage} {...getRootProps({ className: 'dropzone' })}>
      <div className={css.chooseImageText}>
        <input {...getInputProps()} />
        <Icons name="uploadImage" />
        <h2 className={css.chooseImage}>{singleUploadLabel}</h2>
      </div>
    </div>
  );
}
export default PhotosFormDropZone;
