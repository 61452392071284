import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from '../../../../util/reactIntl';
import { Icons } from '../../../../components';

import css from './EditListingJobForm.module.css';

function ImageUploadWrapper(props) {
  const intl = useIntl();

  const { onAttachmentUpload, isSingleUpload = false, images } = props;

  const [numFilesUploaded, setNumFilesUploaded] = useState(0);
  const maxUploads = 10; // Maximum number of uploads allowed

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const remainingUploads = maxUploads - images?.length;
      const filesToAdd = acceptedFiles.slice(0, remainingUploads);
      filesToAdd.forEach(file => {
        onAttachmentUpload(file);
        setNumFilesUploaded(prevNum => prevNum + 1);
      });
    },
    disabled: numFilesUploaded >= maxUploads,
    maxFiles: 10,
  });

  const chooseAnImageTitle = intl.formatMessage({
    id: 'PhotosFormDropZone.dropImageTitle',
  });

  const singleUploadLabel = intl.formatMessage({
    id: 'PhotosFormDropZone.singleUploadLabel',
  });

  return (
    <div className={css.addImage} {...getRootProps({ className: 'dropzone' })}>
      <div className={css.dropImageText}>
        <input {...getInputProps()} />
        {isSingleUpload ? (
          <>
            <Icons name="uploadIcon" />
            <div className={css.textLabel}>{singleUploadLabel}</div>
          </>
        ) : (
          <>
            <h2 className={css.chooseImage}>{chooseAnImageTitle}</h2>
          </>
        )}
      </div>
    </div>
  );
}
export default ImageUploadWrapper;
