import React, { useCallback, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  H3,
  FieldTextInput,
  FieldSelect,
  SecondaryButton,
  Heading,
  LocationAutocompleteInput,
} from '../../../../components';

// Import modules from this directory
import ExperienceSection from './ExperienceSection';
import PriceSection from './PriceSection';
import { isArrayLength, useStateRef } from '../../../../util/genericHelpers';
import ImageUploadWrapper from './ImageUploadWrapper';
import ImagesRedorderWrapper from '../ImagesRedorderWrapper/ImagesRedorderWrapper';
import PhotosFormDropZone from '../ImagesRedorderWrapper/PhotosFormDropZone';
import KeyResponsibilitiesSection from './KeyResponsibilitiesSection';
import WorkExperienceSection from './WorkExperienceSection';
import ManufacturerSection from './ManufacturerSection';

import css from './EditListingJobForm.module.css';

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingJobForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingJobForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingJobForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingJobForm = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        unitType,
        isNewURI,
        isPublishedOrPendingApproval,
        createListingDraftInProgress,
        categoryOptions,
        marketTypeOptions,
        experienceLevelOptions,
        images,
        onImageUpload,
        onRemoveImage,
        listingImageConfig,
        currentListing,
        form: {
          change,
          batch,
          mutators: { push, remove },
        },
      } = formRenderProps;
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const savedImagesUUIDs = isArrayLength(images) && images.map(i => i?.id?.uuid);

      const [uploadedImages, setUploadedImages, uploadedImagesRef] = useStateRef(
        isArrayLength(savedImagesUUIDs) ? savedImagesUUIDs : null
      );

      const [isDraggingOver, setIsDraggingOver] = useState(false);

      const onDragEnter = useCallback(() => {
        if (uploadedImages?.length == 10) {
          setIsDraggingOver(false);
        } else {
          setIsDraggingOver(true);
        }
      }, [uploadedImagesRef?.current]);

      const onImageUploadHandler = async file => {
        setIsDraggingOver(false);
        if (file) {
          const response = await onImageUpload(
            { id: `${file.name}_${Date.now()}`, file },
            listingImageConfig
          );
          // Use the functional update form of the state setter
          // This ensures you are always modifying the most recent state
          setUploadedImages(prevState => {
            // Check if prevState is an array, if not initialize it as an empty array
            const safePrevState = Array.isArray(prevState) ? prevState : [];
            // Return a new array with the old state and the new uuid

            const images = [...safePrevState, response.uuid];
            change('images', images);
            return images;
          });
        }
      };

      const onReorderSharetribeImages = order => {
        // Assuming uploadedImagesRef.current holds your array
        let array = uploadedImagesRef.current;

        // Extracting oldIndex and newIndex from the order object
        const { oldIndex, newIndex } = order;

        // If the indexes are out of the array range, do nothing
        if (
          oldIndex < 0 ||
          oldIndex >= array?.length ||
          newIndex < 0 ||
          newIndex >= array?.length
        ) {
          return;
        }

        // Remove the item from the old position and store it
        const [item] = array.splice(oldIndex, 1);

        // Insert the item at the new position
        array.splice(newIndex, 0, item);

        // Optionally, update the state or reference to reflect the new order
        uploadedImagesRef.current = array;
        setUploadedImages(uploadedImagesRef.current);
        change('images', uploadedImagesRef.current);
      };

      const handleAddressChange = value => {
        form.change('hiringLocation', value);
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />
          <H3 as="h1">
            {intl.formatMessage({
              id: 'EditListingJobForm.title',
            })}
          </H3>
          <div className={css.formRow}>
            <FieldTextInput
              name="title"
              id={`${formId}.jobTitle`}
              placeholder={intl.formatMessage({
                id: 'EditListingJobForm.jobTitlePlaceholder',
              })}
              className={css.formFld}
              type="text"
              label={intl.formatMessage({
                id: 'EditListingJobForm.jobTitleLabel',
              })}
              validate={validators.required(
                intl.formatMessage({ id: 'EditListingJobForm.jobTitleRequired' })
              )}
            />
            <FieldSelect
              name="marketType"
              id={`${formId}.marketType`}
              placeholder={intl.formatMessage({ id: 'EditListingJobForm.jobCategoryPlaceholder' })}
              className={css.formFld}
              type="text"
              label={intl.formatMessage({ id: 'EditListingJobForm.jobCategoryLabel' })}
              validate={validators.required(
                intl.formatMessage({ id: 'EditListingJobForm.jobCategoryRequired' })
              )}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'EditListingJobForm.jobCategoryDefaultOption' })}
              </option>
              {marketTypeOptions.map(({ option, label }, index) => {
                return (
                  <option key={index} value={option}>
                    {label}
                  </option>
                );
              })}
            </FieldSelect>
          </div>

          <div className={css.formRow}>
            <ManufacturerSection
              push={push}
              remove={remove}
              values={values}
              intl={intl}
              formId={formId}
              categoryOptions={categoryOptions}
            />
          </div>
          <div className={css.formRow}>
            <ExperienceSection
              intl={intl}
              showAsRequired={true}
              experienceLevelOptions={experienceLevelOptions}
            />
          </div>
          <div className={css.formRow}>
            <KeyResponsibilitiesSection intl={intl} formId={formId} values={values} push={push} />
          </div>
          <div className={css.formRow}>
            <WorkExperienceSection intl={intl} formId={formId} values={values} push={push} />
          </div>
          <div className={css.formRow}>
            <PriceSection
              unitType={unitType}
              values={values}
              intl={intl}
              showAsRequire={true}
              formId={formId}
              change={change}
              batch={batch}
            />
          </div>

          <div className={classNames(css.formRow, css.fullWidth)}>
            <FieldTextInput
              name="description"
              id={`${formId}.description`}
              placeholder={intl.formatMessage({
                id: 'EditListingJobForm.descriptionPlaceholder',
              })}
              className={css.formFld}
              type="textarea"
              label={intl.formatMessage({ id: 'EditListingJobForm.descriptionLabel' })}
            />
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <label htmlFor="hiringLocation">
              {intl.formatMessage({
                id: 'EditListingJobForm.hiringLocationLabel',
              })}
            </label>
            <Field
              className={css.formFld}
              name="hiringLocation"
              render={({ input, meta }) => (
                <LocationAutocompleteInput
                  placeholder={intl.formatMessage({
                    id: 'EditListingJobForm.hiringLocationPlaceholder',
                  })}
                  iconClassName={css.searchInputIcon}
                  inputClassName={css.searchInput}
                  className={css.formFld}
                  predictionsClassName={css.searchPredictions}
                  input={{ ...input, onChange: handleAddressChange }}
                  meta={meta}
                  shouldHideSearchIcon={true}
                />
              )}
            />
          </div>
          <div
            className={css.imagesField}
            onDragEnter={onDragEnter}
            onDragLeave={() => setIsDraggingOver(false)}
            onDragEnd={() => setIsDraggingOver(false)}
            onDragExit={() => setIsDraggingOver(false)}
          >
            <Heading as="h3" rootClassName={css.sectionTitle}>
              <FormattedMessage id="EditListingDetailsForm.addPhotos" />{' '}
            </Heading>
            {isDraggingOver ? (
              <div
                className={classNames(css.imageUploadWrapper, {
                  [css.imageUploadWrapperDraggingOver]: values?.images?.length >= 1,
                })}
              >
                <ImageUploadWrapper
                  images={images}
                  uploadedImagesRef={uploadedImagesRef.current}
                  onAttachmentUpload={onImageUploadHandler}
                  label={intl.formatMessage({
                    id: 'PhotosFormDropZone.chooseAnImageTitle',
                  })}
                />
              </div>
            ) : (
              <ImagesRedorderWrapper
                intl={intl}
                onImageUploadHandler={onImageUploadHandler}
                photosFormDropZoneContainer={
                  <div className={css.photosFormDropZoneContainer}>
                    <PhotosFormDropZone
                      images={images}
                      onAttachmentUpload={onImageUploadHandler}
                      label={intl.formatMessage({
                        id: 'PhotosFormDropZone.chooseAnImageTitle',
                      })}
                      acceptedTypes={{
                        'image/jpeg': [],
                        'image/png': [],
                      }}
                    />
                  </div>
                }
                onReorder={(_, order) => onReorderSharetribeImages(order)}
                onRemoveImage={r => {
                  onRemoveImage(r);
                  const currentImage = isArrayLength(images) ? images.find(i => i?.id === r) : null;
                  const currentImageId = currentImage?.imageId?.uuid || currentImage?.id?.uuid;
                  const filteredImages = uploadedImagesRef.current?.filter(
                    i => i !== currentImageId
                  );
                  setUploadedImages(filteredImages);
                  change('images', filteredImages);
                }}
                currentListing={currentListing}
                images={images}
              />
            )}
          </div>

          <div className={css.editListingBtns}>
            {isNewURI && (
              <SecondaryButton
                className={css.submitButton}
                type="button"
                inProgress={createListingDraftInProgress}
                disabled={submitDisabled}
                ready={submitReady}
                onClick={() => {
                  change('isDraft', true);
                  handleSubmit(values);
                }}
              >
                {intl.formatMessage({
                  id: 'EditListingJobForm.saveAsDraft',
                })}
              </SecondaryButton>
            )}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {intl.formatMessage({
                id: isPublishedOrPendingApproval
                  ? 'EditListingJobForm.editJobForm'
                  : 'EditListingJobForm.publishJob',
              })}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingJobForm.defaultProps = {
  className: null,
  formId: 'EditListingJobForm',
  fetchErrors: null,
  hasExistingListingType: false,
};

EditListingJobForm.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
};

export default compose(injectIntl)(EditListingJobForm);
