/**
 * Transaction process graph for plain inquiries:
 *   - Hire Designer
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // A customer can initiate a transaction with an inquiry, and
  // then transition that with a request.
  HIRE_DESINGER: 'transition/hire-designer_request',
  SEND_CONTRACT: 'transition/send-contract',
  ACCEPT_CONTRACT: 'transition/accept-contract',
  DECLINE_CONTRACT: 'transition/decline-contract',
  EXPIRE_CONTRACT: 'transition/expire-contract',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  REQUEST_SENT: 'request-sent',
  CONTRACT_SENT: 'contract-sent',
  CONTRACT_EXPIRED: 'contract-expired',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-inquiry/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.HIRE_DESINGER]: states.REQUEST_SENT,
      },
    },

    [states.REQUEST_SENT]: {
      on: {
        [transitions.SEND_CONTRACT]: states.CONTRACT_SENT,
      },
    },
    [states.CONTRACT_SENT]: {
      on: {
        [transitions.ACCEPT_CONTRACT]: states.CONTRACT_ACCEPTED,
        [transitions.DECLINE_CONTRACT]: states.CONTRACT_EXPIRED,
        [transitions.EXPIRE_CONTRACT]: states.CONTRACT_EXPIRED,
      },
    },
    [states.CONTRACT_ACCEPTED]: {
      on: {
        [transitions.ACCEPT_CONTRACT]: states.CONTRACT_ACCEPTED,
      },
    },

    [states.CONTRACT_EXPIRED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.HIRE_DESINGER,
    transitions.SEND_CONTRACT,
    transitions.EXPIRE_CONTRACT,
  ].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
// NOTE: this functions is added just for the sake of consistency
export const isPrivileged = transition => {
  return false;
};

// NOTE: this functions is added just for the sake of consistency
// We don't know if inquiry is on-going or complete
export const statesNeedingProviderAttention = [];
