export const getContractDetails = tx => {
  if (!tx.id) return null;
  return tx?.attributes?.protectedData?.contractDetails || {};
};

export const getLastTransition = tx => {
  if (!tx?.id) return null;
  return tx?.attributes?.lastTransition;
};

export const getAllTransitions = tx => {
  if (!tx?.id) return null;
  return tx?.attributes?.transitions;
};
export const getMilestones = tx => {
  if (!tx.id) return null;
  return tx?.attributes?.protectedData?.milestones || [];
};
export const getCurrentMilestoneAttachments = tx => {
  if (!tx.id) return null;
  return tx?.attributes?.protectedData?.milestoneSubmissionDetails?.milestoneAttachments || [];
};
export const getWeeklyTimeSheet = tx => {
  if (!tx?.id) return null;
  return tx?.attributes?.metadata?.weeklyTimeSheet || {};
};
export const getMessageAttachments = tx => {
  if (!tx?.id) return null;
  return tx?.attributes?.metadata?.attachments || [];
};
export const getProcessName = tx => {
  if (!tx?.id) return null;
  return tx?.attributes?.processName || [];
};
export const getTransactionReadStatus = tx => {
  if (!tx?.id) return null;
  return tx?.attributes?.metadata?.readStatus || {};
};
