import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import css from './UserNav.module.css';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../ducks/user.duck';
import { getUserType } from '../../util/userDataExtraction';
import { UserType } from '../../util/enums';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentUser = useSelector(currentUserSelector);
  const userType = getUserType(currentUser);

  // Define the tabs
  let tabs = [
    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected:
        userType === UserType.COMPANY_USER
          ? currentPage === 'ProfileSettingsPage'
          : currentPage === 'DesignerProfileSettingsPage',
      disabled: false,
      linkProps: {
        name:
          userType === UserType.COMPANY_USER
            ? 'ProfileSettingsPage'
            : 'DesignerProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  // Filter the tabs if the user type is 'designer'
  if (userType === UserType.COMPANY_USER) {
    tabs = tabs.filter(tab => tab.linkProps.name !== 'ProfileSettingsPage');
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
