import moment from 'moment';
import { isArrayLength } from './genericHelpers';
import { getAllTransitionsForEveryProcess } from '../transactions/transaction';
import { INBOX_PAGE_SIZE } from '../containers/InboxPage/InboxPage.duck';
import { TRANSITION_ACCEPT_CONTRACT, TRANSITION_END_CONTRACT } from './types';
import { TransactionRole } from './enums';

export const findRelevantTransactions = transactions => {
  if (!isArrayLength(transactions)) return [];

  // Create a set of parentTransactionIds to optimize lookup
  const parentTransactionIds = new Set(
    transactions
      .filter(transaction => transaction.attributes.protectedData?.parentTransactionId)
      .map(transaction => transaction.attributes.protectedData.parentTransactionId)
  );
  // Filter transactions based on the presence of parentTransactionId and its match with transaction id
  return transactions.filter(transaction => {
    const transactionId = transaction.id.uuid;
    const parentTransactionId = transaction.attributes.protectedData?.parentTransactionId;

    // If transactionId matches a parentTransactionId, skip this transaction
    if (parentTransactionIds.has(transactionId)) {
      return false;
    }

    // If the transaction has a parentTransactionId and it doesn't match any transaction's id.uuid
    // Otherwise, return the transaction if no parentTransactionId is present
    return (
      (parentTransactionId && parentTransactionIds.has(parentTransactionId)) || !parentTransactionId
    );
  });
};

export const sortTransitionsByLatestDate = transitions => {
  if (!isArrayLength(transitions)) {
    return {
      sortedTransitions: [],
      latestCreatedAt: null,
    };
  }

  // Sort the transitions array by createdAt date in descending order (latest first)
  const sortedTransitions = [...transitions].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // Extract the latest createdAt date
  const latestCreatedAt = moment(sortedTransitions[0]?.createdAt).format('lll');
  return {
    sortedTransitions,
    latestCreatedAt,
  };
};

export const fetchTransactionsWithMergedTransitions = async (sdk, page, perPage) => {
  const apiQueryParams = {
    lastTransitions: getAllTransitionsForEveryProcess(),
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: perPage || INBOX_PAGE_SIZE,
  };

  try {
    const response = await sdk.transactions.query(apiQueryParams);
    const transactions = response?.data?.data || [];

    const promises = transactions.map(async transaction => {
      const parentTransactionId = transaction?.attributes?.protectedData?.parentTransactionId;

      if (parentTransactionId) {
        try {
          const parentTransaction = await sdk.transactions.show({ id: parentTransactionId });
          const parentTransitions = parentTransaction?.data?.data?.attributes?.transitions || [];
          const filteredParentTransitions =
            isArrayLength(parentTransitions) &&
            parentTransitions?.filter(tns => tns?.transition !== TRANSITION_ACCEPT_CONTRACT);
          // Merging and sorting the transitions by date (most recent first)
          transaction.attributes.transitions = [
            ...transaction.attributes.transitions,
            ...filteredParentTransitions,
          ].sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
        } catch (error) {
          console.error(`Failed to fetch parent transaction: ${parentTransactionId}`, error);
        }
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Sort transactions based on lastTransitionedAt (latest first)
    const sortedTransactions = transactions.sort((a, b) => {
      return (
        moment(b.attributes.lastTransitionedAt).unix() -
        moment(a.attributes.lastTransitionedAt).unix()
      );
    });
    // Assign the sorted transactions back to the response data
    response.data.data = sortedTransactions;

    return response; // Return the sorted transactions array
  } catch (error) {
    console.error('Failed to fetch transactions:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
};
export const isProviderRole = (latestTransitionBy, transactionRole) => {
  if (transactionRole === TransactionRole.CUSTOMER) return false;
  return (
    latestTransitionBy === TransactionRole.OPERATOR && transactionRole === TransactionRole.PROVIDER
  );
};

// Helper function to check if a transaction has the 'completed' transition
const hasCompletedTransition = transaction => {
  if (!transaction?.id) return null;
  return transaction?.attributes?.transitions.some(
    tns => tns?.transition === TRANSITION_END_CONTRACT
  );
};

export const filteredTransactions = (transactions, filter) => {
  if (!isArrayLength(transactions)) {
    return [];
  }

  // Return all transactions if no filter is applied
  if (!filter) {
    return transactions;
  }

  return transactions.filter(transaction => {
    const isCompleted = hasCompletedTransition(transaction);

    // Return based on filter
    return filter === TRANSITION_END_CONTRACT ? isCompleted : !isCompleted;
  });
};
