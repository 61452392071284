import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import css from './MediaUpload.module.css';
import { Icons } from '../../components';
import { bytesToMB, isArrayLength } from '../../util/genericHelpers';
import { handleDeleteFile, uploadFiles } from '../../util/mediaHelpers';

const MediaUpload = ({
  form,
  fieldName,
  multiple,
  heading1,
  heading2,
  description,
  setIsFileUploading,
}) => {
  const acceptedFileExtensions = '.jpeg,.png,.pdf,.doc,.docx,.dwg,.cmdrw,.cmpck,.skp,.rvt';
  const fieldFiles = form.getState().values[fieldName] || [];
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = async acceptedFiles => {
    const currentFiles = form.getState().values[fieldName] || [];

    // If multiple files are not allowed and there are existing files, prevent additional uploads
    if (!multiple && currentFiles.length > 0) return;

    // Filter out already uploaded files
    const newFiles = acceptedFiles.filter(
      file => !currentFiles.some(existingFile => existingFile.name === file.name)
    );

    if (newFiles.length === 0) return;

    // Combine existing files with new files
    const combinedFiles = [...currentFiles, ...newFiles];
    form.change(fieldName, combinedFiles);

    if (isArrayLength(newFiles)) {
      setIsUploading(true); // Show the progress bar

      await uploadFiles({
        newFiles,
        form,
        fieldName,
        onUploadProgress: (progressEvent, fileName) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(prev => ({
            ...prev,
            [fileName]: progress,
          }));
        },
        setIsFileUploading,
      });

      setUploadProgress({});
      setIsUploading(false); // Hide the progress bar after upload
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileExtensions,
    multiple,
  });

  return (
    <div className={css.videoUploaderSec}>
      <label>{heading1}</label>
      <div className={css.vdoDropArea} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={css.uploadCloud}>
          <Icons name="uploadCloud" />
        </div>
        <p>
          <span>{heading2}</span> <br />
          {description}
        </p>
      </div>
      <div className={css.uploadedFiles}>
        {fieldFiles.map((file, index) => (
          <div className={css.filethumb} key={index}>
            <div className={css.filethumbInfoWrapper}>
              <div className={css.filethumbInfo}>
                <div className={css.leftInfo}>
                  <h2>{file?.name}</h2>
                  <p>{bytesToMB(file?.size)}</p>
                </div>
                <div className={css.rightInfo}>
                  <span
                    className={css.delete}
                    onClick={() => handleDeleteFile({ file, form, fieldName })}
                  >
                    <Icons name="cross" />
                  </span>
                </div>
              </div>
              {isUploading && uploadProgress[file.name] != null && (
                <div className={css.progressBarContainer}>
                  <div
                    className={css.progressBar}
                    style={{ width: `${uploadProgress[file.name]}%` }}
                  ></div>
                  <span>{`${uploadProgress[file.name]}%`}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaUpload;
