import React, { useEffect } from 'react';
import css from './EditListingJobForm.module.css';
import { FieldCurrencyInput, FieldRadioButton } from '../../../../components';
import { Budget } from '../../../../util/enums';
import appSettings from '../../../../config/settings';
import { getPriceValidators } from '../EditListingPricingPanel/EditListingPricingForm';
import { useConfiguration } from '../../../../context/configurationContext';
import classNames from 'classnames';

const PriceSection = ({ intl, values, formId, showAsRequired, unitType, change, batch }) => {
  const config = useConfiguration();
  const listingMinimumPriceSubUnits = config.listingMinimumPriceSubUnits;
  const marketplaceCurrency = config.currency;
  const { budget } = values || {};

  const priceValidators = getPriceValidators(
    listingMinimumPriceSubUnits,
    marketplaceCurrency,
    intl
  );

  useEffect(() => {
    if (budget === Budget.FIXED) {
      batch(() => {
        change('priceTo', null);
        change('priceFrom', null);
      });
    } else if (budget === Budget.HOURLY) {
      change('fixedPrice', null);
    }
  }, [budget, batch, change]);

  return (
    <div className={css.priceSection}>
      <label className={css.sectionLabel}>
        {intl.formatMessage({
          id: 'EditListingJobForm.priceSectionLabel',
        })}
      </label>
      <div className={css.priceRadioButtonRow}>
        {/* <FieldRadioButton
          id={`${formId}.hourly`}
          name="budget"
          label={intl.formatMessage({
            id: 'EditListingJobForm.hourlyPriceLabel',
          })}
          value="hourly"
          showAsRequired={showAsRequired}
          isEditListingPriceRadioBtn={true}
          isEditListingPricehourlyBtn={true}
        /> */}
        <FieldRadioButton
          id={`${formId}.fixed`}
          name="budget"
          label={intl.formatMessage({ id: 'EditListingJobForm.fixedPriceLabel' })}
          value="fixed"
          showAsRequired={showAsRequired}
          isEditListingPriceRadioBtn={true}
          isEditListingPricefixedBtn={true}
        />
      </div>
      {budget === Budget.HOURLY && (
        <div className={classNames(css.priceRangeSection, css.formRow)}>
          <FieldCurrencyInput
            id={`${formId}from`}
            name="priceFrom"
            className={css.formFld}
            label={intl.formatMessage({ id: 'EditListingJobForm.priceFromLabel' }, { unitType })}
            placeholder={intl.formatMessage({ id: 'EditListingJobForm.priceFromPlaceholder' })}
            currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
            validate={priceValidators}
          />
          <FieldCurrencyInput
            id={`${formId}to`}
            name="priceTo"
            className={css.formFld}
            label={intl.formatMessage({ id: 'EditListingJobForm.priceToLabel' }, { unitType })}
            placeholder={intl.formatMessage({ id: 'EditListingJobForm.priceToPlaceholder' })}
            currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
            validate={priceValidators}
          />
        </div>
      )}
      {budget === Budget.FIXED && (
        <div className={classNames(css.fixedPriceSection, css.formRow)}>
          <FieldCurrencyInput
            id={`${formId}fixedPrice`}
            name="fixedPrice"
            className={css.formFld}
            label={intl.formatMessage({ id: 'EditListingJobForm.fixedPriceLabel' }, { unitType })}
            placeholder={intl.formatMessage({ id: 'EditListingJobForm.fixedPricePlaceholder' })}
            currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
            validate={priceValidators}
          />
        </div>
      )}
    </div>
  );
};

export default PriceSection;
